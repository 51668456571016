<template>
    <div class="center-erp-config-box">
        <!-- 详细内容 -->
        <div class="config-content-box">
            <div class="config-content-title">
                <div>
                    <span class="iconfont">&#xe61e;</span>
                    <p>名称: {{ activeLineName }}</p>
                </div>
                <div>
                    <span class="iconfont">&#xe675;</span>
                    <p>工控厂商: {{ activeLine.manufacturers }}</p>
                </div>
                <!-- <div>
                    <span class="iconfont">&#xe664;</span>
                    <p>生产状态: {{ activeLineName }}</p>
                </div> -->
                <div>
                    <span class="iconfont">&#xe640;</span>
                    <p>是否启用: {{ activeLine.is_enabled ? '启用' : '禁用' }}</p>
                </div>
            </div>
            <div class="content-tab">
                <table class="table-table-box" rules="none" ref="tableRef">
                    <tr class="table-tr-one">
                        <th colspan="2">
                            工控系统
                        </th>
                        <th class="zhanwei"></th>
                        <th colspan="7" style="border-right:1px solid #c1c1c3; background: #F5F2EC; color: #823802">
                            ERP系统
                        </th>
                    </tr>
                    <tr class="table-tr-two">
                        <th class="blue-bg-color">
                            工控编号
                        </th>
                        <th class="blue-bg-color">
                            工控料仓类型
                        </th>
                        <td class="zhanwei"></td>
                        <th class="origin-bg-color">
                            料仓名称
                        </th>
                        <th class="origin-bg-color">
                            料仓号
                        </th>
                        <th class="origin-bg-color">
                            料仓编码
                        </th>
                        <th class="origin-bg-color">
                            子类名称
                        </th>
                        <th class="origin-bg-color">
                            规格
                        </th>
                        <th class="origin-bg-color">
                            分配比例(%)
                        </th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
// import _ from 'loadsh';
export default {
    data() {
        return {
            activeLine: {}, // 选中生产线数据
            activeLineIndex: 0, // 生产线信息下标
            activeLineName: '', // 选中生产线名称
            activeStationCode: '', // 目前选中的场站code
            mixstationlineData: [], // 获取生产线数据
        };
    },
    props: {
        extr: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    mounted() {
        this.extr = this.extr || {};
        this.activeStationCode = this.extr.mixstation_code;
        this.activeLineNo = this.extr.line_code;
        this.getLineList();
    },
    methods: {
        // 获取生产线信息
        async getLineList() {
            this.mixstationlineData = [];
            this.activeLine = [];
            await this.$axios
                .get(`/interfaceApi/BaseInfo/stationmanger/get_line_list?stationId=${this.activeStationCode}`)
                .then(res => {
                    if (res.length > 0) {
                        this.mixstationlineData = res;
                        this.mixstationlineData.forEach((item, index) => {
                            if (item.no === this.activeLineNo) {
                                this.activeLine = res[index];
                                this.activeLineNo = res[index].no;
                                this.activeLineName = res[index].name;
                            }
                        });
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};

</script>
<style lang='stylus'>
.status-btn
    width: 2rem !important
    height .35rem !important
    font-size: .16rem !important
    margin-bottom .01rem !important
    margin-top 0 !important
    border-radius 0 !important
.confirm
    border 1px solid #FE7903 !important
    color #FE7903 !important
.confirm-ing
    border-radius 0 !important
    border 1px solid #C2C2C2 !important
    color #02AAC7 !important
.to-be-sent-locking
    border-radius 0 !important
    // border 1px solid #FE7903 !important
    // color #FFFFFF !important
    border 1px solid #C2C2C2 !important
    color #333 !important
.to-be-sent
    border-radius 0 !important
    border 1px solid #C2C2C2 !important
    color: #5588f1 !important
button
    cursor:pointer;
// 表格颜色设置
.el-table .warning-row {
    background: oldlace;
}

.el-table .success-row {
    background: #f0f9eb;
}
.flex-box
    display flex
.center-erp-config-box
    width 100%
    display: flex;
    flex-direction: column;
    overflow: hidden;
.content-tab
    width 100%
    height 100%
    overflow-x hidden
    overflow-y auto
.zhanwei
    width .1rem !important
    background none !important
    border-top none !important
    border-bottom none !important
    border-left 1px solid #C1C1C3 !important
    border-right 1px solid #C1C1C3 !important
.table-table-box
    min-width 100%
    border none
    th,td
        height 0.4rem
    tr:last-child
        td
            border-bottom 1px solid #C1C1C3
    .table-tr-one
        height .4rem
        th
            font-size .18rem
            border 1px solid #C1C1C3
            border-right none
            &:first-child
                color #022782
                background #EEF0F6
                border-right 1px solid #C1C1C3
            &:last-child
                background #fff
                border none !important
.table-tr-two
    th
        border-bottom 1px solid #C1C1C3
        border-left 1px solid #C1C1C3
    .blue-bg-color
        background #F6F8FE
        color #022782
        width 2.2rem
        height .4rem
        font-size .18rem
        border-right 1px solid #C1C1C3
    .origin-bg-color
        background#FCF8F5
        color #823802
        width 2.2rem
        height .4rem
        font-size .18rem
        border-right 1px solid #C1C1C3
.current-color
    background #E2EAF3 !important
.table-tr-content
    td:first-child
            border-left 1px solid #C1C1C3
    td
        height .4rem
        font-size .16rem
        text-align center
        border-right 1px solid #C1C1C3
        border-top 1px solid #D6D6D8
        button
            width .6rem
            height .26rem
            border-radius .04rem
            color #FE7903
            border-color #FE7903
            background none
        .el-input-number
            line-height 0.36rem
            border 1px solid #DCDFE6
            border-radius 2px
            .el-input-number__decrease,
            .el-input-number__increase
                top 0
                height 0.36rem
                line-height 0.36rem
                display none
            .el-input-number__decrease
                left 0
            .el-input-number__increase
                right 0
            .el-input__inner
                border none
                padding 0 0.1rem
    .gongkong-content
        color #333333
        border-right 1px solid #C1C1C3
    .erp-content
        color #823802
        background #FFFEFF
        .please-select
            color #979EA7
        b
            display inline-block
            width: 0.6rem;
            height: 0.26rem;
            border-radius: 0.04rem;
            border 1px solid #fe7903
            color: #fe7903;
            border-color: #fe7903;
            background: none;
            font-weight normal
            cursor pointer
        .xuanze
            color #5588F1
            border-color #5588F1
.operation-list
    width 2rem
    height .8rem
    text-align center
    line-height .8rem
    background #5588F1
    margin-left .22rem
    color #fff
    font-size .2rem
    display inline-block
    cursor:pointer;
    button
        width 2rem
        height .3rem
        margin-top .1rem
        font-size .16rem
        border none
        border-radius 0px


// 分割线
.header-tabs
    width 100%
    background #fff
    padding .2rem
    .el-tabs__item.is-active
        color #D6000F
    .el-tabs__nav div
        color #333333
        font-size .18rem
    .el-tabs__active-bar
        background #D6000F
    .el-tabs__nav-wrap::after
        height 1px
    .el-tabs__active-bar
        bottom: 1px;
.product-code-box
    .active-color
        background #409EFF
        border-color #409EFF
        color #fff
// 详细信息
.config-content-box
    width 100%
    margin-top .2rem
    background #FFFFFF
    padding 0 .2rem .2rem
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
.config-content-title
    display flex
    height .53rem
    align-items center
    div
        display flex
        color #333333
        font-size .18rem
        width 2.53rem
        span
            font-size .2rem
            display inline-block
            margin-right .05rem
        &:nth-child(1) span
            color #5588F1
        &:nth-child(2) span
            color #EB656F
        &:nth-child(3) span
            color #21BDCC
        &:nth-child(4) span
            color #FE7903
</style>
